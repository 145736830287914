import { useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../api/api";
import { Category, Product, SelectedCategory, SelectedProduct, SmallCategory } from "../../api/_type";
import Winylo from "../../winylo";
import Carousel from "../Carousel/Carousel";
import style from "./CategorySelectProduct.module.css";
import carouselStyle from "./Carousel.module.css";
import { MY_DOMAIN, removeHtmlTags } from "../../utils/utils";
import Table from "../../lib/Table/Table";

interface CheckboxProductProps {
  category: Category;
  product: Product;
  selectedProduct?: SelectedProduct;
}

function CheckboxProduct(props: CheckboxProductProps) {
  const queryClient = useQueryClient();

  const { mutate: createSelectedProduct } = useMutation(api.selectedProduct.addSelectedProduct, {
    onSuccess: (selectedProduct) => {
      queryClient.setQueryData<SelectedCategory[]>("selectedCategories", (old: SelectedCategory[] | undefined) => {
        if (old === undefined) return [];

        return [
          ...old.map((sc: SelectedCategory) => {
            return {
              ...sc,
              category: {
                ...sc.category,
                selectedProductsLength:
                  sc.category.id === selectedProduct.category.id ? sc.category.selectedProductsLength + 1 : sc.category.selectedProductsLength,
                subCategories: sc.category.subCategories.map((sub: SmallCategory) => {
                  if (sub.id === selectedProduct.category.id) {
                    return {
                      ...sub,
                      selectedProductsLength: sub.selectedProductsLength + 1,
                    };
                  }

                  return { ...sub };
                }),
              },
            };
          }),
        ];
      });

      queryClient.setQueryData<SelectedProduct[]>("catalog_products", (old: SelectedProduct[] | undefined) => {
        if (old === undefined) return [];

        return [...old, selectedProduct];
      });

      queryClient.setQueryData<SelectedProduct[]>("selectedProducts", (old: SelectedProduct[] | undefined) => {
        if (old === undefined) return [];

        return [...old, selectedProduct];
      });

      queryClient.setQueryData("category", (old: any) => {
        return {
          ...old,
          selectedProducts: [...old.selectedProducts, selectedProduct],
        };
      });
    },
  });

  const { mutate: deleteSelectedProduct } = useMutation(api.selectedProduct.deleteSelectedProduct, {
    onSuccess: (data, idSelectedProductDeleted) => {
      queryClient.setQueryData<SelectedCategory[]>("selectedCategories", (old: SelectedCategory[] | undefined) => {
        if (old === undefined) return [];

        return [
          ...old.map((sc: SelectedCategory) => {
            return {
              ...sc,
              category: {
                ...sc.category,
                selectedProducts: !!sc.category.selectedProducts
                  ? sc.category.id === idSelectedProductDeleted.category
                    ? {
                        ...sc.category.selectedProducts.filter((selectedProduct: any) => selectedProduct.id !== idSelectedProductDeleted),
                      }
                    : { ...sc.category.selectedProducts }
                  : (undefined as any),
                selectedProductsLength:
                  sc.category.id === idSelectedProductDeleted.category ? sc.category.selectedProductsLength - 1 : sc.category.selectedProductsLength,
                subCategories: sc.category.subCategories.map((sub: SmallCategory) => {
                  if (sub.id === idSelectedProductDeleted.category) {
                    return {
                      ...sub,
                      selectedProductsLength: sub.selectedProductsLength - 1,
                    };
                  }

                  return { ...sub };
                }),
              },
            };
          }),
        ];
      });

      // queryClient.invalidateQueries("category");

      queryClient.setQueryData<Category | undefined>("category", (old: Category | undefined) => {
        if (old === undefined) return undefined;

        return {
          ...old,
          selectedProducts: old.selectedProducts.filter(
            (selectedProduct: SelectedProduct) => selectedProduct.id !== idSelectedProductDeleted.product
          ),
        };
      });

      queryClient.setQueryData<SelectedProduct[]>("catalog_products", (old: SelectedProduct[] | undefined) => {
        if (old === undefined) return [];

        return old.filter(
          (selectedProduct) =>
            selectedProduct.product.id !== idSelectedProductDeleted.product && selectedProduct.category.id !== idSelectedProductDeleted.category
        );
      });

      queryClient.setQueryData<SelectedProduct[]>("selectedProducts", (old: SelectedProduct[] | undefined) => {
        if (old === undefined) return [];

        return old.filter(
          (selectedProduct) =>
            selectedProduct.product.id !== idSelectedProductDeleted.product && selectedProduct.category.id !== idSelectedProductDeleted.category
        );
      });

      queryClient.setQueryData("category", (old: any) => {
        return {
          ...old,
          selectedProducts: old.selectedProducts.filter((selectedProduct: any) => selectedProduct.id !== idSelectedProductDeleted),
        };
      });
    },
  });

  function checkboxChange() {
    if (props.selectedProduct === undefined) {
      createSelectedProduct({
        idCategory: props.category.id,
        idProduct: props.product.id,
      });
    } else {
      deleteSelectedProduct({ product: props.selectedProduct.id, category: props.category.id });
    }
  }

  return <Winylo.Checkbox key={props.product.id} checked={props.selectedProduct !== undefined} onChange={checkboxChange} />;
}

interface Props {
  category: SmallCategory;
  setSelectedTab?: (index: number) => void;
}

export default function CategorySelectProduct(props: Props) {
  const [search, setSearch] = useState<string>("");

  const [products, setProducts] = useState<Product[]>([]);
  const [productsTotalLength, setProductsTotalLength] = useState<number>(0);

  const productsRef = useRef<HTMLDivElement>(null);

  const { data: category } = useQuery("category", () => api.categories.getOneCategory(parseInt(props.category?.id.toString() || "0")));

  const {
    refetch: refetchProducts,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(
    "products",
    ({ pageParam = 1 }) =>
      api.product.getProducts({
        page: pageParam,
      }),
    {
      onSuccess: (data) => {
        let temp = data.pages.map((page) => page.items).flat();

        temp.sort((a, b) => {
          const sc = category?.selectedProducts.find((selectedProduct) => selectedProduct.product.id === a.id);

          return sc ? -1 : 1;
        });

        setProducts(temp);
        setProductsTotalLength(data.pages[0].pagination.totalCount);
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    productsRef.current?.addEventListener("scroll", eventHandler);

    function eventHandler() {
      const scrollHeight = productsRef.current?.scrollHeight || 0;
      const scrollTop = productsRef.current?.scrollTop || 0;
      const clientHeight = productsRef.current?.clientHeight || 0;

      if (scrollHeight - scrollTop <= clientHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      productsRef.current?.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  function getProducts() {
    return products?.filter((product) => product.name.toLowerCase().includes(search.toLowerCase()));
  }

  function getDescription(product: Product) {
    let res = removeHtmlTags(product.description);
    return res.length > 100 ? res.substring(0, 100) + "..." : res;
  }

  function renderProduct(product: Product) {
    return (
      <>
        <td>
          <CheckboxProduct
            category={category!}
            product={product}
            selectedProduct={category?.selectedProducts.find((selectedProduct) => selectedProduct.product.id === product.id)}
          />
        </td>
        <td className={style.categoryFirstCol}>
          <Carousel medias={product.medias} style={carouselStyle} unselectedColor="#bdbdbd" selectedColor="#3668af" isMini={true} canZoom />
          <div className={style.categoryName}>{product.name}</div>
        </td>
        <td className={style.categoryDescription}>{getDescription(product)}</td>
        <td>
          {product.priceHt
            ? product.priceHt
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"
            : "-"}
        </td>
        <td>
          {product.priceTtc
            ? product.priceTtc
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"
            : "-"}
        </td>
        <td>{"-"}</td>
        <td>
          {product.priceHt && product.priceTtc
            ? (product.priceTtc - product.priceHt)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"
            : "-"}
        </td>
      </>
    );
  }

  return (
    <>
      <div className={style.cardHeader}>
        <Winylo.Input
          placeholder="Recherche"
          inputContainerProps={{
            className: style.cardHeaderInput,
          }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
        <Winylo.ImportantNumber
          style={{ marginTop: 0, marginLeft: "1.875rem" }}
          number={productsTotalLength}
          text={"Produit"}
          textPlural={"Produits"}
        />
      </div>
      {products && products.length > 0 ? (
        <div ref={productsRef} className={style.productsContainer} style={{ marginLeft: "1.875rem", textAlign: "left" }}>
          <Table className={style.table}>
            <thead>
              <tr>
                <th style={{ width: "1rem" }}>Cocher</th>
                <th style={{ width: "5rem" }}>Produits</th>
                <th style={{ width: "5rem" }}>Description</th>
                <th style={{ width: "5rem" }}>Prix de vente HT</th>
                <th style={{ width: "5rem" }}>Prix de vente TTC</th>
                <th style={{ width: "5rem" }}>Promotions</th>
                <th style={{ width: "5rem" }}>TVA</th>
              </tr>
            </thead>
            <tbody>
              {getProducts()?.map((product) => (
                <tr key={product.id} className={style.separator}>
                  {renderProduct(product)}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className={style.productContainerEmpty} onClick={() => props.setSelectedTab && props.setSelectedTab(1)}>
          <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
          <span style={{ marginTop: "1rem" }}>Vous n'avez aucun produit pour le moment.</span>
          <span>Cliquez ici pour en créer un !</span>
        </div>
      )}
    </>
  );
}
