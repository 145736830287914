import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import GoBack from "../../component/GoBack/GoBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheck,
  faPen,
  faPlusCircle,
  faTimes,
  faTrash,
  faArrowCircleUp,
  faArrowCircleDown,
  faMailBulk,
  faEnvelope,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import style from "./Catalog.module.css";
import classNames from "classnames";
import {
  Catalogue,
  Category,
  Media,
  PriceType,
  PRICE_TYPE,
  Product,
  PublicCatalogue,
  SelectedCategory,
  SelectedProduct,
  SmallCategory,
} from "../../api/_type";
import { useEffect, useRef, useState } from "react";
import Winylo from "../../winylo";
import CatalogSelectCategory from "../../component/CatalogSelectCategory/CatalogSelectCategory";
import Carousel from "../../component/Carousel/Carousel";
import CreateCategoryComponent from "../../component/CreateCategoryComponent/CreateCategoryComponent";
import { HexColorPicker } from "react-colorful";
import "./colorPickerStyle.css";
import { ReactComponent as FacebookIcon } from "../../svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../svg/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../svg/linkedin.svg";
import { ReactComponent as InstagramIcon } from "../../svg/instagram.svg";
import { ReactComponent as WebsiteIcon } from "../../svg/website.svg";
import { ReactComponent as CompanyInfosIcon } from "../../svg/companyInfos.svg";
import ProductComponent from "../../component/ProductComponent/ProductComponent";
import carouselStyle from "./Carousel.module.css";
import CategorySelectProduct from "../../component/CategorySelectProduct/CategorySelectProduct";
import CreateProductComponent from "../CreateProductComponent/CreateProductComponent";

import Cropper from "react-cropper";
import { useContext } from "react";
import { ShepherdTourContext } from "react-shepherd";
import Separator from "../../winylo/components/Separator";
import { CustomerContactForm } from "../../component/CustomerContactForm/CustomerContactForm";
import Switch from "../../component/Switch/Switch";
import { useDebounce, getBase64, MY_DOMAIN } from "../../utils/utils";
import useWindowDimensions from "../../utils/Dimensions";
import ReactQuill from "react-quill";
import { AwesomeQRCode } from "@awesomeqr/react";
import selectedProduct from "../../api/selectedProduct";

interface ICatalogProps {
  publicCatalog?: PublicCatalogue;
}

export default function Catalog({ publicCatalog }: ICatalogProps) {
  const { id, token } = useParams<{ id: string; token: string }>();

  const navigate = useNavigate();
  const tour = useContext(ShepherdTourContext);
  const { width: windowWidth } = useWindowDimensions();
  const queryClient = useQueryClient();

  const [edit, setEdit] = useState<boolean>(token === undefined);
  const [preview, setPreview] = useState<boolean>(false);

  const [logoPreview, setLogoPreview] = useState<any>(undefined);
  const [bannerPreview, setBannerPreview] = useState<any>(undefined);

  const [search, setSearch] = useState<string>("");

  const [isModalContactOpen, setIsModalContactOpen] = useState<number>(-1);
  const [isModalAddProductOpen, setAddProductCategory] = useState<SmallCategory | undefined>(undefined);
  const [selectedCategory, setSelectedCategory] = useState<SmallCategory | undefined>(undefined);
  const [productToShow, setProductToShow] = useState<Product | undefined>(undefined);
  const [isModalAddCategoryOpen, setIsModalAddCategoryOpen] = useState<any>(undefined);
  const [isModalSettingsOpen, setIsModalSettingsOpen] = useState<boolean>(false);
  const [isModalCatalogInfoOpen, setIsModalCatalogInfoOpen] = useState<boolean>(false);
  const [isModalEditCompanyInfoOpen, setIsModalEditCompanyInfoOpen] = useState<boolean>(false);
  const [isModalCompanyInfoOpen, setIsModalCompanyInfoOpen] = useState<boolean>(false);
  const [isModalLogoOpen, setIsModalLogoOpen] = useState<boolean>(false);
  const [isModalBannerOpen, setIsModalBannerOpen] = useState<boolean>(false);
  const [priceType, setPriceType] = useState<PRICE_TYPE>(PRICE_TYPE.BOTH);
  const [color, setColor] = useState<string>("#3668AF");
  const [logo, setLogo] = useState<File | undefined>(undefined);
  const [banner, setBanner] = useState<File | undefined>(undefined);

  const [phone, setPhone] = useState<string>("");
  const [phoneChecked, setPhoneChecked] = useState<boolean>(false);
  const [mail, setMail] = useState<string>("");
  const [mailChecked, setMailChecked] = useState<boolean>(false);
  const [facebook, setFacebook] = useState<string>("");
  const [facebookChecked, setFacebookChecked] = useState<boolean>(false);
  const [twitter, setTwitter] = useState<string>("");
  const [twitterChecked, setTwitterChecked] = useState<boolean>(false);
  const [linkedin, setLinkedin] = useState<string>("");
  const [linkedinChecked, setLinkedinChecked] = useState<boolean>(false);
  const [instagram, setInstagram] = useState<string>("");
  const [instagramChecked, setInstagramChecked] = useState<boolean>(false);
  const [website, setWebsite] = useState<string>("");
  const [websiteChecked, setWebsiteChecked] = useState<boolean>(false);
  const [companyInfos, setCompanyInfos] = useState<string>("");
  const [companyInfosChecked, setCompanyInfosChecked] = useState<boolean>(false);
  const [qrCodeChecked, setQRcodeChecked] = useState<boolean>(false);
  const [isContactable, setIsContactable] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [catalogUrl, setCatalogUrl] = useState<string>("");
  const [qrOptions, setQrOptions] = useState<{ [key: string]: any }>({});

  const [isSaveConfirmed, setIsSaveConfirmed] = useState<boolean>(false);

  const [name, setName] = useState<string | undefined>(undefined);

  const [editName, setEditName] = useState<boolean>(false);

  const [catalog, setCatalog] = useState<Catalogue | PublicCatalogue | undefined>(undefined);
  const [catalogProducts, setCatalogProducts] = useState<SelectedProduct[]>([]);

  const [cropper, setCropper] = useState<Cropper | undefined>(undefined);

  const [logo64, setLogo64] = useState<string | undefined>(undefined);
  const [banner64, setBanner64] = useState<string | undefined>(undefined);

  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  useQuery("catalog", () => api.catalogue.getOneCatalogue(parseInt(id!)), { onSuccess: (data) => setCatalog(data), enabled: id !== undefined });
  // useQuery("catalog", () => api.catalogue.getPublicCatalogue(token!), { onSuccess: (data) => setCatalog(data) });

  useQuery("catalog_products", () => api.selectedProduct.getSelectedProductsByCatalog(parseInt(id!)), {
    onSuccess: (data) => setCatalogProducts(data),
    enabled: id !== undefined,
  });

  useQuery("selectedCategories", () => api.catalogue.getSelectedCategoriesByCatalog(parseInt(id!)), {
    onSuccess: (data) => {
      setSelectedCategories(data);
      // setSelectedCategory(undefined);
    },
    enabled: !!id,
  });

  useQuery("catalog_products", () => api.selectedProduct.getSelectedProductsByPublicCatalog(token!), {
    onSuccess: (data) => setCatalogProducts(data),
  });

  useQuery("selectedCategories", () => api.catalogue.getSelectedCategoriesByPublicCatalog(token!), {
    onSuccess: (data) => {
      setSelectedCategories(data);
      // setSelectedCategory(undefined);
    },
    enabled: !!token,
  });

  useQuery(["selectedProducts", selectedCategory?.id], () => api.categories.getSelectedProductsByCategories((selectedCategory as any).id), {
    onSuccess: (data) => setSelectedProducts(data),
    enabled: !!selectedCategory?.id && selectedCategory.id > 0,
  });

  // const { mutate: getSelectedProducts } = useMutation(api.categories.getSelectedProductsByCategories, {
  //   onSuccess: (data) => {
  //     setSelectedProducts(data);
  //   },
  // });

  const { mutate: updateCatalogue, isLoading } = useMutation(api.catalogue.updateCatalogue, {
    onSuccess: (data) => {
      queryClient.setQueryData("catalog", data);
    },
  });

  const { mutate: uploadLogo, isLoading: isLoadingLogo } = useMutation(api.catalogue.updateImage, {
    onSuccess: (data) => {
      queryClient.setQueryData("catalog", data);
    },
  });

  const { mutate: uploadBanner, isLoading: isLoadingBanner } = useMutation(api.catalogue.updateBanner, {
    onSuccess: (data) => {
      queryClient.setQueryData("catalog", data);
    },
  });

  const { mutate: deleteMedia } = useMutation(api.medias.deleteMedia, {
    onSuccess: (data, deletedMediaId) => {
      if (catalog!.banner?.id === deletedMediaId) {
        queryClient.setQueryData("catalog", { ...catalog, banner: null });
      } else if (catalog!.media?.id === deletedMediaId) {
        queryClient.setQueryData("catalog", { ...catalog, media: null });
      }
    },
  });

  const { mutate: updateSelectedCategoryPosition } = useMutation(api.selectedCategory.updateSelectedCategoryPosition, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData<SelectedCategory[]>("selectedCategories", (old: SelectedCategory[] | undefined) => {
        if (old === undefined) return [];

        const destination = old.find((tempSC) => tempSC.position === variable.newPosition) || variable.selectedCategory;
        destination.position = variable.selectedCategory.position;
        const destinationIndex = old.indexOf(destination);

        old[old.indexOf(variable.selectedCategory)] = destination;
        old[destinationIndex] = { ...variable.selectedCategory, position: data.position };

        return old;
      });
    },
  });

  const { mutate: updateSubCategoryPosition } = useMutation(api.categories.updateSubCategoryPosition, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData<SelectedCategory[]>("selectedCategories", (old: SelectedCategory[] | undefined) => {
        if (old === undefined) return [];
        const parent = old.find((tempSC) => tempSC.category.id === variable.parentId);
        if (parent === undefined) return old;

        const children = parent.category.subCategories;

        const destination = children.find((tempSub) => tempSub.position === variable.newPosition) || variable.subCategory;
        destination.position = variable.subCategory.position;
        const destinationIndex = children.indexOf(destination);

        children[children.indexOf(variable.subCategory)] = destination;
        children[destinationIndex] = { ...variable.subCategory, position: data.position };

        return old;
      });
    },
  });

  function saveSettings() {
    updateCatalogue(
      { catalogueId: catalog!.id, priceType: priceType, color: color?.replaceAll("#", ""), isContactable: isContactable, isActive: isActive },
      {
        onSuccess: () => {
          setIsModalSettingsOpen(false);
        },
      }
    );
  }

  function dataURLtoBlob(dataurl: string) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function saveLogo() {
    if (logo === undefined) return;

    if (!logoPreview) {
      const data = cropper?.getCroppedCanvas().toDataURL();
      let metadata = {
        type: "image/png",
      };

      if (data !== undefined) {
        let blob = dataURLtoBlob(data);

        let file = new File([blob], "test.jpg", metadata);
        uploadLogo(
          { catalogueId: catalog!.id, image: file },
          {
            onSuccess: () => {
              setIsModalLogoOpen(false);
            },
          }
        );
      }
    } else {
      uploadLogo(
        { catalogueId: catalog!.id, image: logo },
        {
          onSuccess: () => {
            setIsModalLogoOpen(false);
          },
        }
      );
    }
  }

  function saveBanner() {
    if (banner === undefined) return;

    if (!bannerPreview) {
      const data = cropper?.getCroppedCanvas().toDataURL();
      let metadata = {
        type: "image/png",
      };

      if (data !== undefined) {
        let blob = dataURLtoBlob(data);

        let file = new File([blob], "test.jpg", metadata);

        uploadBanner(
          { catalogueId: catalog!.id, image: file },
          {
            onSuccess: () => {
              setIsModalBannerOpen(false);
            },
          }
        );
      }
    } else {
      uploadBanner(
        { catalogueId: catalog!.id, image: banner },
        {
          onSuccess: () => {
            setIsModalBannerOpen(false);
          },
        }
      );
    }
  }

  function saveCatalogInfo() {
    updateCatalogue(
      {
        catalogueId: catalog!.id,
        showPhone: phoneChecked,
        showMail: mailChecked,
        showFacebook: facebookChecked,
        showTwitter: twitterChecked,
        showLinkedin: linkedinChecked,
        showInstagram: instagramChecked,
        showWebsite: websiteChecked,
        phone,
        mail,
        facebook,
        twitter,
        linkedin,
        instagram,
        website,
      },
      {
        onSuccess: () => {
          setIsModalCatalogInfoOpen(false);
        },
      }
    );
  }

  function saveCompanyInfos() {
    updateCatalogue(
      {
        catalogueId: catalog!.id,
        showCompanyInfos: companyInfosChecked,
        showQRcode: qrCodeChecked,
        companyInfos,
      },
      {
        onSuccess: () => {
          setIsModalEditCompanyInfoOpen(false);
        },
      }
    );
  }

  function clickPreview() {
    setEdit(false);
    setPreview(true);
  }

  function clickQuitPreview() {
    setEdit(true);
    setPreview(false);
  }

  function clickSave() {
    navigate("/catalogs");
  }

  function renderCategoryList(p_selectedCategory: SelectedCategory) {
    let l_category: Category = p_selectedCategory.category;

    return (
      <div key={`catalog_sc_${l_category.id}`}>
        {
          <>
            <div className={classNames(style.listCategory, l_category.id === selectedCategory?.id && style.listSelected)}>
              <span onClick={() => setSelectedCategory(l_category)}>
                {l_category.name} (
                {l_category.selectedProductsLength + l_category.subCategories.reduce((sum, a) => sum + a.selectedProductsLength, 0)})
              </span>
              {edit && (
                <>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={style.icon}
                    onClick={() => setAddProductCategory(l_category)}
                    title="Ajouter un produit"
                  />
                  {canMoveCategory(p_selectedCategory, selectedCategories.length || 0, -1) ? (
                    <FontAwesomeIcon
                      icon={faArrowCircleUp}
                      className={style.icon}
                      onClick={() => moveCategory(p_selectedCategory, -1)}
                      title="Monter la sous-catégorie"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faArrowCircleUp} className={classNames(style.icon, style.iconDisabled)} title="Monter la catégorie" />
                  )}
                  {canMoveCategory(p_selectedCategory, selectedCategories.length || 0, 1) ? (
                    <FontAwesomeIcon
                      icon={faArrowCircleDown}
                      className={style.icon}
                      onClick={() => moveCategory(p_selectedCategory, 1)}
                      title="Descendre la sous-catégorie"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faArrowCircleDown} className={classNames(style.icon, style.iconDisabled)} title="Descendre la catégorie" />
                  )}
                </>
              )}
            </div>
            {l_category.subCategories && l_category.subCategories.length > 0 && (
              <div style={{ marginLeft: "2rem", fontSize: "0.9286rem" }}>
                {l_category.subCategories.map((item: SmallCategory) => {
                  return renderSubcategoryList(item, l_category.subCategories.length);
                })}
              </div>
            )}
          </>
        }
      </div>
    );
  }

  function canMoveCategory(p_selectedCategory: SelectedCategory | undefined, p_listSize: number, p_increment: number) {
    if (p_selectedCategory !== undefined) {
      const c_index = selectedCategories.indexOf(p_selectedCategory);

      return c_index + p_increment >= 0 && c_index + p_increment < p_listSize;
    } else {
      return false;
    }
  }

  function moveCategory(p_selectedCategory: SelectedCategory | undefined, p_increment: number) {
    if (p_selectedCategory !== undefined) {
      const c_position = p_selectedCategory.position;

      updateSelectedCategoryPosition({
        selectedCategory: p_selectedCategory,
        newPosition: c_position + p_increment,
      });
    }
  }

  function renderSubcategoryList(p_subCategory: SmallCategory, p_listSize: number) {
    return (
      <div
        key={`catalog_sub_${p_subCategory.id}`}
        className={classNames(style.listCategory, p_subCategory.id === selectedCategory?.id && style.listSelected)}
      >
        <span onClick={() => setSelectedCategory(p_subCategory)}>
          {p_subCategory.name} ({p_subCategory.selectedProductsLength})
        </span>
        {edit && (
          <>
            <FontAwesomeIcon
              icon={faPlusCircle}
              className={style.icon}
              onClick={() => setAddProductCategory(p_subCategory)}
              title="Ajouter un produit"
            />
            {canMoveSubCategory(p_subCategory, p_listSize, -1) ? (
              <FontAwesomeIcon icon={faArrowCircleUp} className={style.icon} onClick={() => moveSubCategory(p_subCategory, -1)} title="Monter" />
            ) : (
              <FontAwesomeIcon icon={faArrowCircleUp} className={classNames(style.icon, style.iconDisabled)} title="Monter" />
            )}
            {canMoveSubCategory(p_subCategory, p_listSize, 1) ? (
              <FontAwesomeIcon icon={faArrowCircleDown} className={style.icon} onClick={() => moveSubCategory(p_subCategory, 1)} title="Monter" />
            ) : (
              <FontAwesomeIcon icon={faArrowCircleDown} className={classNames(style.icon, style.iconDisabled)} title="Monter" />
            )}
          </>
        )}
      </div>
    );
  }

  function canMoveSubCategory(p_category: SmallCategory, p_listSize: number, p_increment: number) {
    if (p_category !== undefined) {
      const c_position = p_category.position;
      return c_position + p_increment > 0 && c_position + p_increment <= p_listSize;
    } else {
      return false;
    }
  }

  function moveSubCategory(p_subCategory: SmallCategory, p_increment: number) {
    const c_position = p_subCategory.position;
    p_subCategory.parent &&
      updateSubCategoryPosition({
        subCategory: p_subCategory,
        newPosition: c_position + p_increment,
        parentId: p_subCategory.parent.id,
      });
  }

  const { mutate: deleteSelectedProduct } = useMutation(api.selectedProduct.deleteSelectedProduct, {
    onMutate: (params) => {
      queryClient.setQueryData<SelectedCategory[]>("selectedCategories", (old: SelectedCategory[] | undefined) => {
        if (old === undefined) return [];

        return [
          ...old.map((sc: SelectedCategory) => {
            return {
              ...sc,
              category: {
                ...sc.category,
                selectedProductsLength:
                  sc.category.id === params.category ? sc.category.selectedProductsLength - 1 : sc.category.selectedProductsLength,
                subCategories: sc.category.subCategories.map((sub: SmallCategory) => {
                  if (sub.id === params.category) {
                    return {
                      ...sub,
                      selectedProductsLength: sub.selectedProductsLength - 1,
                    };
                  }

                  return { ...sub };
                }),
              },
            };
          }),
        ];
      });

      if (selectedCategory) {
        setSelectedProducts((old) => {
          return [...old.filter((sp) => sp.id !== params.product)];
        });
      }

      queryClient.setQueryData<SelectedProduct[]>("catalog_products", (old: SelectedProduct[] | undefined) => {
        if (old === undefined) return [];
        return [...old.filter((sp) => sp.id !== params.product)];
      });
    },
    onSuccess: (data, idSelectedProductDeleted) => {},
  });

  function renderProduct(product: Product, selectedProduct: SelectedProduct) {
    return (
      <div
        key={`catalog_p_${product.id}_${selectedProduct.id}`}
        className={style.productContainer}
        onClick={() => {
          setScrollHeight(window.scrollY);
          setProductToShow(product);
          window.scrollTo(0, 0);
        }}
      >
        {/* <div
          className={style.productImage}
          style={{
            backgroundImage: "url('" + product.medias[0]?.uri || process.env.PUBLIC_URL + "/no_media.jpg",
          }}
        ></div> */}
        <Carousel medias={product.medias} style={carouselStyle} unselectedColor="#bdbdbd" selectedColor={getColor()} thumbnails />
        <Separator style={{ marginTop: "2.5rem" }}></Separator>
        <div className={style.productContent}>
          <div className={style.productName}>{product.name}</div>
          {catalog?.priceType !== PRICE_TYPE.NONE && (
            <div className={style.priceContainer}>
              {(catalog?.priceType === PRICE_TYPE.TTC || catalog?.priceType === PRICE_TYPE.BOTH) && (
                <span className={style.priceTtc}>{product.priceTtc ? product.priceTtc + "€ TTC" : " "}</span>
              )}
              {(catalog?.priceType === PRICE_TYPE.HT || catalog?.priceType === PRICE_TYPE.BOTH) && (
                <span className={style.priceHt}>{product.priceHt ? product.priceHt + "€ HT" : " "}</span>
              )}
            </div>
          )}
          <div className={style.productDescription} dangerouslySetInnerHTML={{ __html: product.description }}>
            {/* {removeHtmlTags(product.description)} */}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
            <Winylo.Button size="small" format="square" className={style.productMoreInfo} style={{ backgroundColor: getColor() }}>
              Plus d'infos
            </Winylo.Button>
            <div>
              {isContactable && selectedProduct.id && (
                <FontAwesomeIcon
                  title="Prendre contact"
                  icon={faEnvelope}
                  className={classNames(style.productIcon, style.contact)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsModalContactOpen(product.id);
                  }}
                />
              )}
              {edit && selectedProduct.id && (
                <FontAwesomeIcon
                  title="Supprimer du catalogue"
                  icon={faTrash}
                  className={classNames(style.productIcon, style.delete)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteSelectedProduct({ product: selectedProduct.id, category: selectedProduct.category.id });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function handleLogoChange(e: any) {
    let file = e.currentTarget.files[0];
    setLogo(file);

    if (file !== undefined) {
      getBase64(file).then((base64: string) => {
        setLogo64(base64);
        if (file.type.includes("video")) {
          setLogoPreview(
            <video style={{ width: "10.4286rem", height: "10.4286rem", alignSelf: "center" }} autoPlay muted key={file.name}>
              <source src={base64} />
            </video>
          );
        } else {
          setLogoPreview(null);
        }
      });
    }
  }

  function handleBannerChange(e: any) {
    let file = e.currentTarget.files[0];
    setBanner(file);
    if (file !== undefined) {
      getBase64(file).then((base64: string) => {
        setBanner64(base64);
        if (file.type.includes("video")) {
          setBannerPreview(
            <video style={{ width: "7.2857rem", height: "30.8571rem" }} autoPlay muted key={file.name}>
              <source src={base64} />
            </video>
          );
        } else {
          setBannerPreview(null);
        }
      });
    }
  }

  useEffect(() => {
    if (catalog) {
      setName(catalog.name);
      setPriceType(catalog.priceType);
      setColor(catalog.color || "#3668AF");
      setPhoneChecked(catalog.showPhone || false);
      setMailChecked(catalog.showMail || false);
      setFacebookChecked(catalog.showFacebook || false);
      setTwitterChecked(catalog.showTwitter || false);
      setLinkedinChecked(catalog.showLinkedin || false);
      setInstagramChecked(catalog.showInstagram || false);
      setWebsiteChecked(catalog.showWebsite || false);
      setCompanyInfosChecked(catalog.showCompanyInfos || false);
      setQRcodeChecked(catalog.showQRcode || false);
      setIsContactable(catalog.isContactable || false);
      setIsActive(catalog.isActive || false);
      setPhone(catalog.phone || "");
      setMail(catalog.mail || "");
      setFacebook(catalog.facebook || "");
      setTwitter(catalog.twitter || "");
      setLinkedin(catalog.linkedin || "");
      setInstagram(catalog.instagram || "");
      setWebsite(catalog.website || "");
      setCompanyInfos(catalog.companyInfos || "");

      setCatalogUrl(`${MY_DOMAIN}/c/${catalog.publicToken}`);
    }
  }, [catalog]);

  useEffect(() => {
    if (!catalog) return;

    api.companies
      .getBrandImage(catalog.company.id)
      .then((blob) => {
        setQrOptions({
          text: catalogUrl,
          colorLight: "#ffffff",
          dotScale: 1,
          logoImage: URL.createObjectURL(blob),
          logoScale: 0.25,
          logoCornerRadius: 50,
          margin: 0,
        });
      })
      .catch((err) => {
        setQrOptions({
          text: catalogUrl,
          colorLight: "#ffffff",
          dotScale: 1,
          logoScale: 0.5,
          logoCornerRadius: 50,
          margin: 0,
        });
      });
  }, [catalogUrl]);

  useEffect(() => {
    setSelectedProducts([]);
    selectedCategory && selectedCategory.id && queryClient.refetchQueries("selectedProducts");
    // selectedCategory && getSelectedProducts(selectedCategory.id);
  }, [selectedCategory]);

  const [scrollHeight, setScrollHeight] = useState<number>(0);

  useEffect(() => {
    if (!productToShow) {
      window.scrollTo(0, scrollHeight);
    }
  }, [productToShow]);

  useEffect(() => {
    if (isModalAddProductOpen) return;

    queryClient.invalidateQueries("selectedProducts");
  }, [isModalAddProductOpen]);

  useEffect(() => {
    document.addEventListener("cancel-action-createcatalog6", () => {
      setEditName(false);
    });

    if (publicCatalog) {
      setCatalog(publicCatalog);
    }
  }, []);

  function getColor() {
    return "#" + color?.replaceAll("#", "");
  }

  function getProductsToShow() {
    if (catalog === undefined) return undefined;

    return !selectedCategory ? (
      catalogProducts
        .filter((selectedProduct) => selectedProduct.product.name.toLowerCase().includes(search.toLowerCase()))
        .map((selectedProduct) => renderProduct(selectedProduct.product, selectedProduct))
    ) : edit && selectedProducts.length === 0 ? (
      <div className={style.noProducts}>Ajouter des produits dans la catégorie</div>
    ) : (
      selectedProducts
        .filter((selectedProduct) => selectedProduct.product.name.toLowerCase().includes(search.toLowerCase()))
        .map((selectedProduct) => renderProduct(selectedProduct.product, selectedProduct))
    );
  }

  function handleTrash(e: any, media: Media) {
    e.stopPropagation();

    if (media) {
      deleteMedia(media.id);
    }
  }

  function invalidateQueries() {
    queryClient.refetchQueries("selectedCategories");
    queryClient.invalidateQueries("catalog_products");
  }

  function isModalOpen() {
    return (
      !isModalAddCategoryOpen && !isModalLogoOpen && !isModalBannerOpen && !isModalCatalogInfoOpen && !isModalSettingsOpen && !isModalAddProductOpen
    );
  }

  function handleCompanyInfosIconClick() {
    setIsModalCompanyInfoOpen(true);
  }

  return (
    <div
      style={
        edit
          ? {
              display: "flex",
              margin: "auto",
              maxWidth: "93.75rem",
              height: "100%",
              flexDirection: "column",
              position: "relative",
            }
          : {
              display: "flex",
              margin: "auto",
              maxWidth: "93.75rem",
              height: "100%",
              flexDirection: "column",
              position: "relative",
            }
      }
    >
      {isModalOpen() && (edit || preview) && (
        <div className={style.topBar}>
          {edit && (
            <GoBack
              text={productToShow ? "Retour à la liste des produits" : "Retour à la liste des catalogues"}
              onClick={() => {
                if (productToShow) {
                  setProductToShow(undefined);
                } else {
                  navigate("/catalogs");
                }
              }}
              className={style.goBack}
              style={{ margin: "20px 0" }}
            />
          )}
          {preview && (
            <Winylo.Button variant="red" size="small" format="square" onClick={clickQuitPreview} className={style.quitPreview}>
              Quitter l'aperçu
            </Winylo.Button>
          )}
          {edit && (
            <div className={style.topButtons}>
              <Winylo.Button variant="blue" size="small" format="square" onClick={clickPreview} style={{ marginRight: ".625rem" }}>
                Aperçu
              </Winylo.Button>
              <Winylo.Button
                id="onboarding-createcatalog-11th"
                className="onboarding-createcatalog-11th"
                variant="yellow"
                size="small"
                format="square"
                onClick={clickSave}
              >
                Terminer
              </Winylo.Button>
            </div>
          )}
        </div>
      )}
      <div style={{ height: "4rem" }}></div>
      <Winylo.Card
        text={edit ? "Créer un catalogue" : undefined}
        style={
          preview
            ? {
                marginTop: "2.8875rem",
              }
            : {}
        }
        className={classNames(edit ? style.editMode : style.preview ? undefined : style.public, "onboarding-createcatalog-2nd")}
      >
        {catalog === undefined ? (
          <></>
        ) : productToShow !== undefined ? (
          <ProductComponent
            catalog={catalog}
            product={productToShow}
            goBack={() => setProductToShow(undefined)}
            showGoBack={!edit}
            setProductToShow={setProductToShow}
            isEdit={edit}
            isContactable={isContactable}
            setIsModalContactOpen={setIsModalContactOpen}
          />
        ) : (
          <>
            <div style={{ marginBottom: ".625rem", height: "2.2679rem", display: "flex", justifyContent: "space-between" }}>
              {edit ? (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Winylo.Button
                    id="onboarding-createcatalog-3rd"
                    className="onboarding-createcatalog-3rd"
                    variant="blue"
                    format="square"
                    size="small"
                    onClick={() => setIsModalSettingsOpen(true)}
                  >
                    Paramétrage
                  </Winylo.Button>
                  <div style={{ marginLeft: "0.5rem", fontSize: "1.25rem" }}>
                    <FontAwesomeIcon icon={faEye} style={{ marginRight: "0.5rem" }} />
                    {catalog.views}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className={style.catalogInfo} style={{ color: "#323232" }}>
                {edit && (
                  <FontAwesomeIcon
                    title="Modifier les contacts"
                    icon={faPen}
                    className={style.icon}
                    onClick={() => setIsModalCatalogInfoOpen(true)}
                  />
                )}
                <span style={{ fontWeight: "600" }}>
                  {(edit || catalog.showPhone) &&
                    ((
                      <span className={style.icon} onClick={() => window.open(`tel:${catalog.phone}`)}>
                        {catalog.phone}
                      </span>
                    ) ||
                      "Téléphone")}{" "}
                  {catalog.showPhone && catalog.showMail && "|"}{" "}
                  {(edit || catalog.showMail) &&
                    ((
                      <span className={style.icon} onClick={() => window.open(`mailto:${catalog.mail}`)}>
                        {catalog.mail}
                      </span>
                    ) ||
                      "Mail")}
                </span>
                {(edit || catalog.showFacebook) && (
                  <FacebookIcon
                    color={`#${catalog.color || "323232"}`}
                    title="Facebook"
                    className={style.icon}
                    onClick={() => window.open(facebook)}
                  />
                )}
                {(edit || catalog.showTwitter) && (
                  <TwitterIcon
                    color={`#${catalog.color || "323232"}`}
                    title="Twitter"
                    className={style.icon}
                    onClick={() => {
                      window.open(twitter);
                    }}
                  />
                )}
                {(edit || catalog.showLinkedin) && (
                  <LinkedinIcon
                    color={`#${catalog.color || "323232"}`}
                    title="Linkedin"
                    className={style.icon}
                    onClick={() => window.open(linkedin)}
                  />
                )}
                {(edit || catalog.showInstagram) && (
                  <InstagramIcon
                    color={`#${catalog.color || "323232"}`}
                    title="Instagram"
                    className={style.icon}
                    onClick={() => window.open(instagram)}
                  />
                )}
                {(edit || catalog.showWebsite) && (
                  <WebsiteIcon
                    color={`#${catalog.color || "323232"}`}
                    title="Site personnalisé"
                    className={style.icon}
                    onClick={() => window.open(website)}
                  />
                )}
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div
                className={classNames(style.catalogBanner, catalog.banner === null && style.empty)}
                onClick={() => {
                  if (edit) setIsModalBannerOpen(true);
                }}
              >
                {catalog.banner === null ? (
                  <FontAwesomeIcon icon={faCamera} className={style.cameraBanner} />
                ) : catalog.banner.mimeType.includes("video") ? (
                  <div className={style.catalogBannerVideo}>
                    <video autoPlay loop muted style={{ width: "100%", height: "100%", position: "absolute", top: 0 }}>
                      <source src={catalog.banner.uri} />
                    </video>
                  </div>
                ) : (
                  <div className={style.bannerImage} style={{ backgroundImage: `url(${catalog.banner.uri})` }}>
                    {edit && (
                      <FontAwesomeIcon icon={faTrash} className={style.trashLogo} onClick={(e) => handleTrash(e, catalog.banner!)}></FontAwesomeIcon>
                    )}
                  </div>
                )}
              </div>
              <div
                className={classNames(style.catalogLogo, catalog.media === null && style.empty)}
                onClick={(e) => {
                  e.stopPropagation();
                  if (edit) setIsModalLogoOpen(true);
                }}
              >
                {catalog.media === null ? (
                  <div className={style.cameraLogoContainer}>
                    <FontAwesomeIcon icon={faCamera} className={style.cameraLogo} />
                  </div>
                ) : catalog.media.mimeType.includes("video") ? (
                  <video autoPlay loop muted style={{ width: "100%" }}>
                    <source src={catalog.media.uri} />
                  </video>
                ) : (
                  <div className={style.logoImage} style={{ backgroundImage: `url(${catalog.media.uri})` }}>
                    {edit && (
                      <FontAwesomeIcon icon={faTrash} className={style.trashLogo} onClick={(e) => handleTrash(e, catalog.media!)}></FontAwesomeIcon>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={style.catalogueContent}>
              <div
                style={{
                  flex: 1,
                  minWidth: "300px",
                  paddingRight: "2.0714rem",
                  borderRight: "2px solid var(--border-color)",
                }}
              >
                <div className={style.catalogNameContainer}>
                  {editName ? (
                    <div className="onboarding-createcatalog-6th" style={{ display: "flex", alignItems: "center" }}>
                      <Winylo.Input
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        inputContainerProps={{ style: { marginBottom: "0px" } }}
                        icon={<></>}
                      />
                      <FontAwesomeIcon
                        id="onboarding-createcatalog-editname-cancel"
                        icon={faTimes}
                        className={style.nameEdit}
                        onClick={() => {
                          if (tour?.isActive()) return;
                          setEditName(false);
                          setName(catalog.name);
                        }}
                      />
                      <FontAwesomeIcon
                        id="onboarding-createcatalog-editname-check"
                        icon={faCheck}
                        className={classNames(style.nameEdit, "onboarding-createcatalog-editname-check")}
                        onClick={() => {
                          setEditName(false);
                          updateCatalogue({ catalogueId: catalog.id, name: name });
                        }}
                      />
                    </div>
                  ) : (
                    <div className={style.catalogNameView}>
                      <span className={style.catalogName} style={{ color: getColor() }}>
                        {name}
                      </span>
                      {edit && (
                        <FontAwesomeIcon
                          id="onboarding-createcatalog-5th"
                          icon={faPen}
                          className={classNames(style.nameEdit, "onboarding-createcatalog-5th")}
                          onClick={() => setEditName(true)}
                          title="Modifier le nom du catalogue"
                        />
                      )}
                    </div>
                  )}
                  <div className={style.catalogInfosIcons}>
                    {(edit || catalog.showCompanyInfos || catalog.showQRcode) && (
                      <CompanyInfosIcon
                        color={`#${catalog.color || "323232"}`}
                        title="Informations de l'entreprise"
                        className={style.icon}
                        onClick={handleCompanyInfosIconClick}
                      />
                    )}
                    {edit && (
                      <FontAwesomeIcon
                        icon={faPen}
                        className={classNames(style.nameEdit)}
                        onClick={() => setIsModalEditCompanyInfoOpen(true)}
                        title="Modifier les informations de l'entreprise"
                      />
                    )}
                  </div>
                </div>
                <Winylo.Separator />
                <div className="onboarding-createcatalog-9th" style={{ marginLeft: "28px", marginRight: "-2.0714rem" }}>
                  <div
                    className={classNames(style.listCategory, selectedCategory === undefined && style.listSelected)}
                    onClick={() => setSelectedCategory(undefined)}
                  >
                    <span>Tous les produits</span>
                  </div>
                  {selectedCategories.map((item: SelectedCategory) => renderCategoryList(item))}
                  {edit && (
                    <Winylo.Button
                      id="onboarding-createcatalog-7th"
                      className="onboarding-createcatalog-7th"
                      format="square"
                      size="small"
                      onClick={() => setIsModalAddCategoryOpen(true)}
                    >
                      Gérer les catégories
                    </Winylo.Button>
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: 2,
                  paddingLeft: "2.4286rem",
                }}
              >
                <div className={style.productsContainerHeader}>
                  <span>{selectedCategory?.name || "Liste des produits"}</span>{" "}
                  <Winylo.Input
                    placeholder={"Recherche"}
                    value={search}
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    inputContainerProps={{ style: { marginBottom: 0 } }}
                  />
                </div>
                <div className={classNames(style.productsContainer, "onboarding-createcatalog-10th")}>{getProductsToShow()}</div>
              </div>
            </div>
          </>
        )}
      </Winylo.Card>
      <Winylo.Modal
        xMarkID="onboarding-catalogselectcategory-back"
        isOpen={isModalAddCategoryOpen}
        onClose={() => setIsModalAddCategoryOpen(false)}
        modalStyle={{ content: { width: "100%", maxWidth: "93.75rem", height: "80vh", display: "flex", flexDirection: "column" } }}
        titlePosition={"left"}
        title="Ajout des catégories"
        childrenContainerStyle={{ display: "flex", flexDirection: "column", overflow: "auto" }}
      >
        <Winylo.Tabs
          selectedItem={selectedTab}
          updateSelectedItem={(index) => setSelectedTab(index)}
          items={[
            {
              title: "Séléctionner les catégories",
              renderFunction: (catalogue: Catalogue, selectedCategories: SelectedCategory[]) => {
                return (
                  <CatalogSelectCategory
                    className="onboarding-createcatalog-8th"
                    reloadSelected={{ selectedCategory, setSelectedCategory }}
                    invalidateQueries={() => invalidateQueries()}
                    catalogue={catalogue}
                    selectedCategories={selectedCategories}
                    setSelectedTab={setSelectedTab}
                  />
                );
              },
              key: "catalog",
            },
            {
              title: "Créer une nouvelle catégorie",
              renderFunction: (catalogue: Catalogue) => <CreateCategoryComponent noRedirect catalogue={catalogue} />,
              key: "newCategory",
            },
          ]}
          itemsDependencies={{
            catalog: [catalog, selectedCategories],
            newCategory: [catalog],
          }}
        />
      </Winylo.Modal>
      <Winylo.Modal
        xMarkID="onboarding-createcatalog-parameters-close"
        isOpen={isModalSettingsOpen}
        onClose={() => setIsModalSettingsOpen(false)}
        modalStyle={{ content: { width: "100%", maxWidth: "34rem", zIndex: 3 } }}
        title="Paramétrage"
      >
        <div className="onboarding-createcatalog-4th">
          <label className={style.label}>Affichage du prix</label>
          <Winylo.Select value={priceType} onChange={(e) => setPriceType(e as PRICE_TYPE)}>
            <option value={PRICE_TYPE.NONE}>{PriceType[PRICE_TYPE.NONE]}</option>
            <option value={PRICE_TYPE.HT}>{PriceType[PRICE_TYPE.HT]}</option>
            <option value={PRICE_TYPE.TTC}>{PriceType[PRICE_TYPE.TTC]}</option>
            <option value={PRICE_TYPE.BOTH}>{PriceType[PRICE_TYPE.BOTH]}</option>
          </Winylo.Select>
          <span className={style.settingLabel}>Contacts</span>
          <div className={style.settingContact}>
            <Switch checked={isContactable} onClick={() => setIsContactable(!isContactable)} />
            <span style={{ marginLeft: "1rem" }}>Prendre contact</span>
          </div>
          <span className={style.settingLabel}>Actif</span>
          <div className={style.settingContact}>
            <Switch checked={isActive} onClick={() => setIsActive(!isActive)} />
            <span style={{ marginLeft: "1rem" }}>Catalogue actif</span>
          </div>
          <p className={style.chooseMainColor}>Choisissez votre couleur principale</p>
          <Winylo.Input
            label="Couleur hexadécimal"
            value={color?.replaceAll("#", "")}
            onChange={(e) => setColor(e.currentTarget.value)}
            icon={<></>}
          />
          <div className={style.colorPreview} style={{ backgroundColor: "#" + color?.replaceAll("#", "") }}></div>
          <HexColorPicker color={color} onChange={setColor} />
          <div style={{ textAlign: "center" }}>
            <Winylo.Button
              id="onboarding-createcatalog-parameters-save"
              className="onboarding-createcatalog-save-4th"
              style={{ width: "50%" }}
              onClick={saveSettings}
              disabled={isLoading}
            >
              Enregistrer
            </Winylo.Button>
          </div>
        </div>
      </Winylo.Modal>
      {/* Modal séléction logo */}
      <Winylo.Modal
        isOpen={isModalLogoOpen}
        onClose={() => setIsModalLogoOpen(false)}
        modalStyle={{ content: { width: "100%", maxWidth: "34rem" } }}
        title="Logo"
      >
        <>
          <Winylo.InputFile
            inputContainerProps={{ className: style.input }}
            label={"Taille de l'image optimale : 146x146"}
            onChange={handleLogoChange}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classNames(style.filePreview, logoPreview === undefined && style.empty)}>
              {logoPreview || (
                <Cropper
                  style={{ height: "100%", width: "100%" }}
                  zoomTo={0}
                  // aspectRatio={1}
                  initialAspectRatio={1}
                  src={logo64}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              )}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Winylo.Button
              style={{ width: "50%", marginTop: "1rem", zIndex: "1", position: "relative" }}
              onClick={saveLogo}
              disabled={isLoadingLogo || logo === undefined}
            >
              Enregistrer
            </Winylo.Button>
          </div>
        </>
      </Winylo.Modal>
      {/* Modal séléction bannière */}
      <Winylo.Modal
        isOpen={isModalBannerOpen}
        onClose={() => setIsModalBannerOpen(false)}
        modalStyle={{ content: { width: "100%", maxWidth: "34rem", zIndex: 3 } }}
        title="Bannière"
      >
        <>
          <Winylo.InputFile
            inputContainerProps={{ className: style.input }}
            label={"Taille de l'image optimale : 1269x300"}
            onChange={handleBannerChange}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classNames(style.bannerPreview, bannerPreview === undefined && style.empty)}>
              {bannerPreview || (
                <Cropper
                  style={{ height: "100%", width: "100%" }}
                  zoomTo={0}
                  // aspectRatio={4.23}
                  initialAspectRatio={4.23}
                  src={banner64}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              )}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Winylo.Button
              style={{ width: "50%", marginTop: "1rem", zIndex: "1", position: "relative" }}
              onClick={() => saveBanner()}
              disabled={isLoadingBanner || banner === undefined}
            >
              Enregistrer
            </Winylo.Button>
          </div>
        </>
      </Winylo.Modal>
      <Winylo.Modal
        isOpen={isModalCatalogInfoOpen}
        onClose={() => setIsModalCatalogInfoOpen(false)}
        modalStyle={{ content: { width: "100%", maxWidth: "34rem" } }}
        title="Contact"
      >
        <>
          <p>Cochez les cases des informations à afficher</p>
          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={phoneChecked} onChange={(e) => setPhoneChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <Winylo.Input label="Téléphone" value={phone} onChange={(e) => setPhone(e.currentTarget.value)} icon={<></>} />
            </div>
          </div>

          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={mailChecked} onChange={(e) => setMailChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <Winylo.Input label="Mail" value={mail} onChange={(e) => setMail(e.currentTarget.value)} icon={<></>} />
            </div>
          </div>

          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={facebookChecked} onChange={(e) => setFacebookChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <Winylo.Input label="Facebook" value={facebook} onChange={(e) => setFacebook(e.currentTarget.value)} icon={<></>} />
            </div>
          </div>

          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={twitterChecked} onChange={(e) => setTwitterChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <Winylo.Input label="Twitter" value={twitter} onChange={(e) => setTwitter(e.currentTarget.value)} icon={<></>} />
            </div>
          </div>

          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={linkedinChecked} onChange={(e) => setLinkedinChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <Winylo.Input label="Linkedin" value={linkedin} onChange={(e) => setLinkedin(e.currentTarget.value)} icon={<></>} />
            </div>
          </div>

          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={instagramChecked} onChange={(e) => setInstagramChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <Winylo.Input label="Instagram" value={instagram} onChange={(e) => setInstagram(e.currentTarget.value)} icon={<></>} />
            </div>
          </div>

          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={websiteChecked} onChange={(e) => setWebsiteChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <Winylo.Input label="Site internet" value={website} onChange={(e) => setWebsite(e.currentTarget.value)} icon={<></>} />
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <Winylo.Button style={{ width: "50%", marginTop: "1rem" }} onClick={saveCatalogInfo} disabled={isLoading}>
              Enregistrer
            </Winylo.Button>
          </div>
        </>
      </Winylo.Modal>

      <Winylo.Modal
        isOpen={isModalEditCompanyInfoOpen}
        onClose={() => setIsModalEditCompanyInfoOpen(false)}
        modalStyle={{ content: { width: "100%", maxWidth: "60rem" } }}
        title="Informations de l'entreprise"
        titleColor={catalog?.color ? "#" + catalog.color : undefined}
      >
        <>
          {/* <p>Cochez les cases des informations à afficher</p> */}
          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Winylo.Checkbox checked={companyInfosChecked} onChange={(e) => setCompanyInfosChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <label className={style.label}>Informations sur l'entreprise</label>
              <ReactQuill
                theme="snow"
                value={companyInfos}
                onChange={(content) => setCompanyInfos(content)}
                style={{ overflowY: "scroll", resize: "vertical", wordBreak: "break-all" }}
              />
            </div>
          </div>

          <div className={style.catalogContactRow}>
            <div className={style.catalogCheckbox}>
              <Switch checked={qrCodeChecked} onClick={(e) => setQRcodeChecked(e.currentTarget.checked)} />
            </div>
            <div style={{ flex: 1 }}>
              <span>Afficher le QRCode du catalogue</span>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <Winylo.Button style={{ width: "50%", marginTop: "1rem" }} onClick={saveCompanyInfos} disabled={isLoading}>
              Enregistrer
            </Winylo.Button>
          </div>
        </>
      </Winylo.Modal>

      <Winylo.Modal
        isOpen={isModalCompanyInfoOpen}
        onClose={() => setIsModalCompanyInfoOpen(false)}
        modalStyle={{ content: { width: "100%", maxWidth: "60rem" } }}
        title="Informations de l'entreprise"
        titleColor={catalog?.color ? "#" + catalog.color : undefined}
      >
        <>
          {(edit || catalog?.showCompanyInfos) && (
            <div className={style.companyInfos} dangerouslySetInnerHTML={{ __html: catalog?.companyInfos || "" }} />
          )}
          {(edit || catalog?.showQRcode) && (
            <>
              <div className={style.scanLabel}>Disponible sur vos smartphones !</div>
              <div className={classNames(style.catalogContactRow, style.qrCodeContainer)}>
                <div style={{ width: "100%", height: "100%", paddingTop: "1rem", paddingBottom: "1rem" }}>
                  <AwesomeQRCode options={qrOptions} />
                </div>
              </div>
            </>
          )}
        </>
      </Winylo.Modal>

      {edit && (
        <Winylo.Modal
          isOpen={!!isModalAddProductOpen}
          onClose={() => setAddProductCategory(undefined)}
          modalStyle={{ content: { width: "100%", maxWidth: "93.75rem", height: "80vh", display: "flex", flexDirection: "column" } }}
          titlePosition={"left"}
          title="Ajout produits"
          childrenContainerStyle={{ display: "flex", flexDirection: "column", overflow: "auto" }}
        >
          <Winylo.Tabs
            items={[
              {
                title: "Séléctionner les produits",
                renderFunction: (category: any) => <CategorySelectProduct category={category} />,
                key: "selectedCategory",
              },
              {
                title: "Ajout nouveau produit",
                renderFunction: (category: any) => <CreateProductComponent category={category} noRedirect={true} />,
                key: "selectedCategory",
              },
            ]}
            itemsDependencies={{
              selectedCategory: [isModalAddProductOpen],
              newProduct: [],
            }}
          />
        </Winylo.Modal>
      )}
      <Winylo.Modal
        isOpen={isContactable && isModalContactOpen > -1}
        onClose={() => setIsModalContactOpen(-1)}
        modalStyle={{
          content: {
            width: "100%",
            maxWidth: "50.75rem",
            height: "fit-content",
            maxHeight: "95%",
            display: "flex",
            flexDirection: "column",
          },
        }}
        titlePosition={"left"}
        title="Prendre contact"
        childrenContainerStyle={{ display: "flex", flexDirection: "column", overflow: "auto" }}
      >
        <CustomerContactForm selectedProductId={isModalContactOpen} setSelectedId={setIsModalContactOpen} setIsSaveConfirmed={setIsSaveConfirmed} />
      </Winylo.Modal>

      <Winylo.ConfirmModal
        isOpen={isSaveConfirmed}
        onClose={() => setIsSaveConfirmed(!isSaveConfirmed)}
        closable
        text={"Votre demande de contact a bien été envoyée !"}
        titlePosition={"left"}
        title={"Demande de contact"}
      />
    </div>
  );
}
