import { useEffect, useState } from "react";
import style from "./CategoryListComponent.module.css";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../api/api";
import { PageItem, Pagination, SmallCatalogue, SmallCategory } from "../../api/_type";
import { MY_DOMAIN, removeHtmlTags } from "../../utils/utils";
import Winylo from "../../winylo";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import Table from "../../lib/Table/Table";
import CategorySelectProduct from "../CategorySelectProduct/CategorySelectProduct";
import CreateProductComponent from "../../pages/CreateProductComponent/CreateProductComponent";
import classNames from "classnames";
import icons from "../../utils/icons";
import CreateCategoryComponent from "../CreateCategoryComponent/CreateCategoryComponent";
import catalogue from "../../api/catalogue";

interface Props {
  parentId?: string;
  setSelectedTab?: (index: number) => void;
  catalogue?: SmallCatalogue;
}

export default function CategoryListComponent({ parentId, ...props }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<SmallCategory | undefined>(undefined);

  const [search, setSearch] = useState<string>("");

  const [categories, setCategories] = useState<SmallCategory[]>([]);
  const [categoriesTotalLength, setCategoriesTotalLength] = useState<number>(0);

  const showSubcategories = parentId !== undefined;

  const { data: category } = useQuery([parentId], () => api.categories.getOneCategory(parseInt(parentId!)), {
    onSuccess: (data) => {
      setCategories(data.subCategories);
      setCategoriesTotalLength(data.subCategories.length);
    },
    enabled: showSubcategories,
  });

  const {
    refetch: refetchCategories,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery("categories", ({ pageParam = 1 }) => api.categories.getCategories({ page: pageParam }), {
    onSuccess: (data) => {
      setCategories(data.pages.map((page) => page.items).flat());
      setCategoriesTotalLength(data.pages[0].pagination.totalCount);
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.pagination.current < lastPage.pagination.endPage) {
        return lastPage.pagination.current + 1;
      } else {
        return undefined;
      }
    },
    keepPreviousData: true,
    enabled: !showSubcategories,
  });

  function changeFormVisibility() {
    setIsFormOpen((b) => !b);
  }

  function changeEditVisibility() {
    setIsEditOpen((b) => !b);
  }

  function editCategory(category: SmallCategory) {
    !showSubcategories ? navigate(`/categories/${category.id}/edit`) : navigate(`/categories/${category.id}/edit?parent=${category.parent!.id}`);
  }

  function subCategoriesList(category: SmallCategory) {
    navigate(`/categories/${category.id}/subcategories`);
  }

  /**
   * @todo Route pas encore faite
   */
  // const { mutate: CloneCategory } = useMutation(api.categories.cloneCategory, {
  //   onSuccess: (result, variable) => {
  //   },
  // });

  const [categoryToDelete, setCategoryToDelete] = useState<SmallCategory | undefined>(undefined);

  const { mutate: deleteCategory } = useMutation(api.categories.deleteCategory, {
    onSuccess: (res, variable) => {
      if (!parentId) {
        queryClient.setQueryData(
          "categories",
          (old: { pages: { items: SmallCategory[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
            if (!old) return old as any;

            return {
              pages: old.pages.map((page: PageItem<SmallCategory>) => {
                return {
                  items: page.items.filter((mediaV2) => mediaV2.id !== categoryToDelete?.id),
                  pagination: { ...page.pagination, totalCount: page.pagination.totalCount - 1 },
                };
              }),
              pageParams: old.pageParams,
            };
          }
        );
      } else {
        queryClient.setQueryData<SmallCategory | undefined>([parentId], (old?: SmallCategory) => {
          if (old === undefined) return old;

          return { ...old, subCategories: [...old.subCategories.filter((data) => data.id !== variable)] };
        });
      }
    },
  });

  function renderSubCategories(subCategories: SmallCategory[]) {
    let res: string = "";
    for (let i = 0; i < subCategories.length; i++) {
      res += subCategories[i].name;
      i + 1 < subCategories.length && (res += ", ");
    }

    return res;
  }

  function renderCategory(category: SmallCategory) {
    return (
      <>
        <td className={style.categoryFirstCol}>
          {category.media?.mimeType?.startsWith("video") ? (
            <video src={category.media?.uri} className={style.productCover} style={{ width: "75px", height: "75px" }} />
          ) : (
            <div
              className={style.categoryImage}
              style={{
                backgroundImage: "url('" + (category.media?.uri || process.env.PUBLIC_URL + "/no_media.jpg") + "')",
                backgroundSize: "cover",
                width: "75px",
                height: "75px",
              }}
            ></div>
          )}
          <div className={style.categoryName}>{category.name}</div>
        </td>
        {!showSubcategories && <td>{renderSubCategories(category.subCategories)}</td>}
        <td>
          <span className={style.categoryDescription}>{removeHtmlTags(category.description)}</span>
        </td>
        <td>
          <div className={style.iconsColumn}>
            {!showSubcategories && (
              <div className={style.actionIconContainer} title="Sous-catégories" onClick={() => subCategoriesList(category)}>
                <FontAwesomeIcon className={classNames(style.actionIcon)} icon={icons.v6_faArrowRightToBracket} />
              </div>
            )}
            <div
              className={style.actionIconContainer}
              title="Gérer des produits"
              onClick={() => {
                changeFormVisibility();
                setSelectedCategory(category);
              }}
            >
              <FontAwesomeIcon className={classNames(style.actionIcon, "onboarding-detailledcategory-1st")} icon={faPlusCircle} />
            </div>

            <div
              className={style.actionIconContainer}
              title="Modifier"
              onClick={() => {
                if (props.setSelectedTab) {
                  changeEditVisibility();
                  setSelectedCategory(category);
                } else {
                  editCategory(category);
                }
              }}
            >
              <FontAwesomeIcon className={classNames(style.actionIcon, "onboarding-detailledcategory-2nd")} icon={faPen} />
            </div>

            <div className={style.actionIconContainer} title="Supprimer" onClick={() => setCategoryToDelete(category)}>
              <FontAwesomeIcon className={classNames(style.actionIcon, "onboarding-detailledcategory-4th")} icon={faTrash} />
            </div>
          </div>
        </td>
      </>
    );
  }

  function confirmDeleteCategory() {
    if (categoryToDelete) {
      deleteCategory(categoryToDelete.id);
      setCategoryToDelete(undefined);
    }
  }

  function getCategories() {
    return categories;
    return categories?.filter((category) => category.name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <>
      <div className={style.cardHeader}>
        <Winylo.Input
          placeholder="Recherche"
          inputContainerProps={{
            className: style.cardHeaderInput,
          }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
        <Winylo.ImportantNumber
          style={{ marginTop: 0, marginLeft: "1.875rem" }}
          number={categoriesTotalLength}
          text={`${showSubcategories ? "Sous-" : ""}Catégorie`}
          textPlural={`${showSubcategories ? "Sous-" : ""}Catégories`}
        />
      </div>
      {categories && categories.length > 0 /*&& categories?.filter((c) => c.parent === null)?.length > 0*/ ? (
        <div className={style.categoriesContainer} style={{ textAlign: "left" }}>
          <Table className={style.table}>
            <thead>
              <tr>
                <th style={{ width: "30%" }}>{`${showSubcategories ? "Sous-" : ""}Catégories`}</th>
                {!showSubcategories && <th style={{ width: "30%" }}>Sous-catégories</th>}
                <th style={{ width: "30%" }}>Description</th>
                <th style={{ width: "20%" }}></th>
              </tr>
            </thead>
            <tbody>
              {getCategories()?.map((category) => {
                return (
                  (!category.parent || showSubcategories) && (
                    <tr key={category.id} className={style.separator}>
                      {renderCategory(category)}
                    </tr>
                  )
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className={style.categoriesContainerEmpty}
          onClick={() => (showSubcategories ? navigate(`/categories/create?parent=${parentId}`) : navigate("/categories/create"))}
        >
          <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
          <span style={{ marginTop: "1rem" }}>{`Cette catégorie ne contient aucune ${showSubcategories ? "sous-" : ""}catégorie pour le moment.`}</span>
          <span>Cliquez ici pour en créer une !</span>
        </div>
      )}
      <Winylo.Modal title="Supprimer la catégorie" isOpen={!!categoryToDelete} onClose={() => setCategoryToDelete(undefined)}>
        <p>
          Voulez-vous vraiment supprimer la catégorie <span style={{ fontWeight: 600 }}>{categoryToDelete?.name}</span> ?
        </p>
        <div className={style.modalDeleteButtons}>
          <Winylo.Button variant="gray" fullWidth onClick={() => setCategoryToDelete(undefined)}>
            Annuler
          </Winylo.Button>
          <Winylo.Button variant="red" fullWidth onClick={confirmDeleteCategory}>
            Supprimer
          </Winylo.Button>
        </div>
      </Winylo.Modal>

      <Winylo.Modal
        isOpen={isFormOpen}
        onClose={changeFormVisibility}
        modalStyle={{ content: { width: "100%", maxWidth: "93.75rem", height: "80vh", display: "flex", flexDirection: "column" } }}
        titlePosition={"left"}
        title="Ajout produits"
        childrenContainerStyle={{ display: "flex", flexDirection: "column", overflow: "auto" }}
      >
        <Winylo.Tabs
          items={[
            {
              title: "Séléctionner les produits",
              renderFunction: (category: any) => <CategorySelectProduct category={category} />,
              key: "selectedCategory",
            },
            {
              title: "Ajout nouveau produit",
              renderFunction: (category: any) => <CreateProductComponent category={category} />,
              key: "selectedCategory",
            },
          ]}
          itemsDependencies={{
            selectedCategory: [selectedCategory],
            newProduct: [],
          }}
        />
      </Winylo.Modal>

      {props.setSelectedTab && (
        <Winylo.Modal
          isOpen={isEditOpen}
          onClose={changeEditVisibility}
          modalStyle={{ content: { width: "100%", maxWidth: "93.75rem", height: "80%" } }}
          titlePosition={"left"}
          title="Edit catégorie"
        >
          {selectedCategory && (
            <CreateCategoryComponent
              onSubmit={() => changeEditVisibility()}
              idCategory={selectedCategory.id.toString()}
              idParent={parentId}
              catalogue={props.catalogue}
            />
          )}
        </Winylo.Modal>
      )}
    </>
  );
}
